/* @import must be at top of file, otherwise CSS will not work */
@import url('https://cdn.fonts.net/t/1.css?apiType=css&projectid=172ecd65-91a4-47cb-a9de-30688e5982ad');

@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNext/AvenirNext-LightItalic.woff2') format('woff2'),
    url('../public/fonts/AvenirNext/AvenirNext-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNext/AvenirNext-LT-Pro-Regular.otf') format('otf');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNext/AvenirNext-Medium.woff2') format('woff2'),
    url('../public/fonts/AvenirNext/AvenirNext-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNext/AvenirNext-LT-Pro-Demi.otf') format('otf');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url('../public/fonts/AvenirNext/AvenirNext-Bold.woff2') format('woff2'),
    url('../public/fonts/AvenirNext/AvenirNext-Bold.woff') format('woff');
}

.grecaptcha-badge {
  display: none !important;
}
